import React, {useState} from 'react'
import {graphql,  navigate} from 'gatsby'
import styled from 'styled-components'
import Project from '../components/Project';
import {ImageBlock} from '../components/ImageShow';
import {useStore} from '../state';
import {useColorModeContext} from '../layouts'
import CloseIcon from '../icons/Close'
import css from '@styled-system/css'
import Seo from '../components/Seo'

const CloseWrapper = styled.div`

  position: absolute;
  cursor:pointer;
  right: 20px;
  top: 34px;

 
  &:hover {
    svg {
      color: #999;
      
    }
  }
${css({
  'svg' : {
    width: ['24px', '32px'],
    height: ['24px', '32px'],
    marginTop: ['-10px', '0px'],
  }}
)}
`



export default (props) => {

        
    const {image_show, stopImageShow} = useStore()
    const [{mode}, dispatch] = useColorModeContext()
    mode === 'dark' || dispatch('dark')
    
    const {data, pageContext, location} = props
    const {project, categories} = data
    const {images, categories_uuid} = project.nodes[0]
    
    const project_categories = categories.nodes.filter(c => categories_uuid.includes(c.category_uuid) && c.group === "project")

    image_show && stopImageShow()

    const imagesPathRegex = new RegExp(/(\/projects\/[^\/]+)\/images/)
    
    const back = () => {
       const match = location && location.pathname && location.pathname.match(imagesPathRegex)
       
       void( match && match[1] ? navigate(match[1]) : navigate('/projects'))
       
    }

    return (

    <Project className="images" project_name={pageContext.project_name} categories={project_categories}>
        
        <CloseWrapper onClick={back}><CloseIcon/></CloseWrapper>
      
        <ImageBlock images={images} full={true}/>         
    </Project>
    )

}


export const pageQuery = graphql`
    query($project_uuid: String){
        project: allMongodbCmsProjects(filter: {project_uuid: {eq: $project_uuid}}) {
          nodes {
            project_slug
            project_name
            project_uuid
            categories_uuid
            images {
              uris {
                _1000
              }
              order
            }
          }
        }
        categories: allMongodbCmsCategories {
            nodes {
              label
              color
              category_uuid
              order
              group
            }
          }
    }     

`